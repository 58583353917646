.tableHeader {
    display: inline-block;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
}

.tableButtonGroup {
    float: right;
}

.tableHeaderHeading {
    float: left;
}

.disabledRow {
    background-color: #EDF2F7;
    color: #a4a5a7;
}

.lockToggleButton {
    background-color: white;
    color: black !important;
}

.customTableData {
    overflow-x: auto;
    display: inline-block;
}

.tableCell {
}