.pageWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.selectorsWrapper {
    display: flex;
    justify-content: space-around;
}

.selectorWrapper {
    text-align: center;
}

.analysisWrapper, .statsContainer {
    display: flex;
    flex-direction: column;
}